$infoContainerBgColor: rgba(0, 0, 0, 0.8);
$infoContainerBgColor1: rgb(171 168 168 / 40%);
$infoContainerItemColor: black;
$infoContainerItemColorHover: #bd965a;
$infoMenuListItem: $infoContainerItemColorHover;
$infoContainerWrapperColor: #aeaeae;
$menuBottomLine: #d5d5d5;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.main-content {
  // overflow: hidden;
  flex: 1;
}

img,
.nav:before,
.info-container .list-item {
  display: block;
}

.logo {
  img {
    width: 234px;
  }

  &.desktop {
    display: none;
  }
}

.logo-container {
  margin: 0 auto;
}

.text-logo,
.nav .burger-nav .menu-close,
.info-container li a,
.menu {
  position: relative;
}

.nav:before,
.nav .burger-nav {
  position: absolute;
}

.text-logo {
  color: white;
  letter-spacing: 0.2em;
  font-size: 15px;
  left: -32.5%;
  top: 41px;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  .burger-nav {
    top: 64px;
    right: 35px;

    .menu-open,
    .menu-close {
      transition: opacity 1s ease-out;
    }

    .menu-open {
      width: 40px;
    }

    .menu-close {
      overflow: hidden;
      height: 0;
      right: -7px;
      top: 8px;
      opacity: 0;
    }
  }

  &.active {
    .burger-nav {
      .menu-open {
        height: 0;
        opacity: 0;
      }

      .menu-close {
        height: 31px;
        opacity: 1;
      }
    }
  }
}

.info-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s linear;
  z-index: 3;

  li {
    a {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 15px;
      color: $infoContainerItemColor;
      text-shadow: $infoContainerItemColor 0 0 0;
      padding: 14px 26px;
      transition: color 0.5s ease;

      &.active {
        color: $infoMenuListItem;
      }
    }

    &:hover a {
      color: $infoContainerItemColorHover;
    }
  }

  .list-item {
    line-height: 34px;
    letter-spacing: 2px;
    padding: 5px 0;
    text-align: center;
  }

  &.active {
    max-height: 399px;
  }
}

.info-container-wrapper.active {
  left: -32%;
  padding: 0;
}

li a {
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  color: $infoContainerWrapperColor;
}

.product-title {
  color: #312f2f;
  letter-spacing: 1px;
}

@media screen and (min-width: 1024px) {
  .main-component-content {
    min-height: calc(100vh - 83px - 215px);
  }

  .burger-nav {
    display: none;
  }

  .menu-container {
    margin-bottom: 25px;
  }

  .logo {
    &.desktop {
      display: block;

      position: relative;
      left: -11px;
      top: 5px;

      img {
        width: 150px;
      }
    }

    &.mobile {
      display: none;
    }
  }

  .logo.desktop {
    top: 1px;
    left: -182px;
  }

  .logo-container {
    position: absolute;
    left: 23%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 2.5%;
  }

  .logo {
    margin-left: 16px;
    padding-bottom: 15px;
    margin-top: 66px;

    img {
      width: 106px;
    }
  }
  .main-content {
    position: relative;
  }

  .text-logo {
    font-size: 20px;
    left: -65.5%;
    top: 34px;
  }

  .info-container {
    max-height: initial;
    display: flex;
    justify-content: center;
    margin-top: 2.5%;
    border-bottom: 1px solid #c6a530;
    height: 83px;
    padding-left: 74px;

    .list-item {
      display: inline-block;
    }
  }
}

@media screen and (min-width: 1440px) {
  .logo.desktop {
    top: -8px;
    left: -106px;
  }

  .info-container {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1670px) {
  .logo.desktop {
    left: 23px;
  }
}

@media screen and (min-width: 1820px) {
  .logo.desktop {
    left: -18px;
  }
}
