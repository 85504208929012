.container img {
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    img {
      width: 500px;
      padding-bottom: 30px;
    }
    margin: 30px 0;
  }

  .gal-photo-1,
  .gal-photo-3 {
    display: flex;
    justify-self: end;
    margin-right: 30px;
  }

  .gal-photo-2,
  .gal-photo-4 {
    display: flex;
    justify-self: start;
    margin-left: 30px;
  }
}
