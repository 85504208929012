$footerBgColor: #00000073;

.footer {
  background-color: $footerBgColor;
  padding: 20px 0 20px;
  text-align: center;
  color: white;
}

.footer-header {
  font-weight: 500;
}

@media screen and (min-width: 1024px) {
  .footer.sticky {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
