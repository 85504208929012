.biorezonans-photo-img {
  width: 80vw;
  margin: 20px auto;
}

@media screen and (min-width: 1024px) {
  .offer-description.biorezonans {
    display: flex;
    justify-content: center;
  }

  .offer-description-text {
    margin-right: 20px;
  }

  .biorezonans-photo-img {
    width: 30vw;
    margin: 20px auto;
  }
}
