.therapy-img {
  width: 80vw;
  margin: 0 auto;
}

.therapy-img-container,
.therapy-img-2 {
  margin-top: 20px;
}

.therapy-text {
  margin: 20px;
  text-align: center;
}

@media screen and (min-width: 720px) {
  .therapy-img {
    width: 50vw;
    position: relative;
  }

  .therapy-img-1 {
    left: -140px;
  }

  .therapy-img-2 {
    left: 140px;
  }

  .therapy-text {
    margin: 60px 40px;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .therapy-main-content {
    display: flex;
    justify-content: center;
    flex-flow: column;
  }

  .therapy-img {
    width: 300px;
  }

  .therapy-img-1 {
    left: 27px;
  }
}

@media screen and (min-width: 1250px) {
  .therapy-main-content {
    justify-content: center;
  }

  .therapy-text {
    width: 47vw;
    margin: 60px auto;
  }

  .therapy-img.therapy-img-1 {
    left: -177px;
  }

  .therapy-img.therapy-img-2 {
    top: -245px;
    right: 177px;
  }
}
