$contactHeaderColor: #bd965a;

.contact-text,
.contact-theme-line {
  text-align: center;
}

.contact-header {
  color: $contactHeaderColor;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.contact-text {
  margin-bottom: 5px;
  font-weight: 500;
}

.contact-theme-line {
  color: $contactHeaderColor;
  font-weight: 500;
  margin-bottom: 30px;
}

.contact-text-container {
  margin-bottom: 30px;
}

.map-container {
  display: flex;
  justify-content: center;
}

iframe {
  width: 90vw;
  margin-bottom: 30px;
}

a {
  color: inherit; /* Dziedziczy kolor tekstu od rodzica */
  text-decoration: none; /* Usuwa podkreślenie linku */
  cursor: pointer;
}

.contact-photo img {
  width: 200px;
  margin: 0 auto 30px;
}

@media screen and (min-width: 1024px) {
  .main-container {
    display: flex;
    justify-content: center;
  }

  iframe {
    width: 40vw;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 7%;
  }

  .map-container {
    align-items: center;
    margin-top: 40px;
  }

  .contact-photo {
    margin-left: 7%;
    img {
      width: 300px;
    }
  }
}
