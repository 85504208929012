.about-me-photo {
  width: 100%;
}

.about-me-section {
  text-align: center;
}

.tile-info {
  font-weight: 500;
  margin: 20px 0;
}

.photo-section {
  padding: 0 20px;
}

.name {
  font-size: 18px;
  position: relative;
  margin-bottom: 28px;
  font-weight: 500;

  &:after {
    position: absolute;
    content: '';
    bottom: -9px;
    width: 100px;
    height: 2px;
    background-color: #bd965a;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.text-section .name {
  margin-top: 55px;
}

.text {
  margin: 0 20px 20px;
}

@media screen and (min-width: 1024px) {
  .photo-section {
    display: flex;
    justify-content: space-evenly;
  }

  .text-section {
    width: 36%;
  }

  .about-me-photo {
    width: 66%;
    margin: 0 auto;
  }

  .photo {
    width: 21%;
  }
}
